/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-16 16:05:52
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-26 22:07:48
 */
import Highcharts from 'highcharts'
import moment from 'moment'
import { mapGetters as mapRootGetters, createNamespacedHelpers } from 'vuex'
import { numberFormat } from '@/plugins/utils'

const { mapGetters, mapActions } = createNamespacedHelpers('Admin/Overview')

export default {
  name: 'trendData',
  data () {
    return {
      // 拆线图总配置
      highchartsOptions: {
        chart: {
          type: 'areaspline'
        },
        credits: {
          enabled: false
        },
        title: {
          text: 'title',
          style: {
            opacity: 0
          }
        },
        legend: {
          enabled: false
        },
        // xAxis: {
        //   categories: [
        //     '11-01','11-02','11-03','11-04','11-05','11-06','11-07'
        //   ]
        // },
        yAxis: {
          title: {
            text: ''
          },
          labels: {
            formatter () {
              return numberFormat(this.value)
            },
            enabled: !/android|iphone/i.test(navigator.userAgent)
          }
        },
        tooltip: {
          shared: true,
          backgroundColor: 'rgba(73, 78, 88, 1)',
          borderRadius: 10,
          shadow: false,
          distance: 20,
          style: {
            fontSize: 12,
            fontFamily: 'AlibabaPuHuiTiBold',
            color: 'rgba(255, 255, 255, 0.7)'
          },
          formatter () {
            return `<span>${this.x}</span><br/>
                    <span style="margin-top: 10;color: #ffffff;font-size: 16;">${numberFormat(this.y)}</span>`;
          }
        },
        plotOptions: {
          areaspline: {
            fillOpacity: 0.2
          }
        }
      },
      // 拆线图数据项配置
      seriesItemOptions: {
        // data: [300000, 400000, 300000, 500000, 400000, 100000, 1000002],
        lineColor: {
          linearGradient: { x1: 0, y1: 0, x2: 1, y2: 0 },
          stops: [
            [0, 'rgba(122, 243, 236, 1)'],
            [1, 'rgba(44, 104, 255, 1)']
          ]
        },
        lineWidth: 6,
        shadow: {
          color: 'rgba(44, 104, 255, 0.24)',
          offsetX: 0,
          offsetY: 6,
          width: 16,
          opacity: 0.05
        },
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 1, y2: 0 },
          stops: [
            [0, 'rgba(122, 243, 236, 0.2)'],
            [1, 'rgba(44, 104, 255, 0.2)']
          ]
        },
        marker: {
          radius: 0,
          states: {
            hover: {
              radius: 8,
              lineWidth: 6,
              lineColor: 'rgba(29, 224, 228, 1)',
              fillColor: '#ffffff'
            }
          }
        }
      },
      currentShow: 1, // 当前展示的数据 1：数据存储量 2：未选中
      trendDataChart: null
    }
  },
  computed: {
    ...mapRootGetters([
      'isMobile',
      'enum_map'
    ]),

    ...mapGetters([
      'trendQueryForm',
      'trendData'
    ]),

    /**
     * 平台选择
     */
    mediaPlatformOptions () {
      return [{
        id: 0,
        name: '全部投放平台'
      }].concat(this.enum_map.media_platform)
    }
  },
  watch: {
    trendData () {
      // 生成数据趋势折线图
      this.createTrendChart()
    }
  },
  mounted () {
    // 生成数据趋势折线图
    this.createTrendChart()
  },
  methods: {

    ...mapActions([
      'updateTrendQueryForm',
      'getTradeData'
    ]),

    /**
     * 时间变化
     */
    async dateChange (newVal) {
      if (Array.isArray(newVal) && newVal[0]) {
        let [startDate, endDate] = newVal
        startDate = moment(startDate).format('YYYY-MM-DD')
        endDate = moment(endDate).format('YYYY-MM-DD')
        this.updateTrendQueryForm({
          start_date: startDate,
          end_date: endDate
        })
      } else if (newVal === null) {
        this.updateTrendQueryForm({
          start_date: '',
          end_date: ''
        })
      }
      await this.getTradeData()
      this.createTrendChart()
    },

    /**
     * 切换平台
     */
    async mediaPlatformChange () {
      await this.getTradeData()
      this.createTrendChart()
    },

    /**
     * 折线图x轴数据
     */
    xAxisOption (categories) {
      return {
        xAxis: {
          categories
        }
      }
    },

    /**
     * 折线图单项数据配置
     */
    seriesItem (data) {
      const isAllZero = data.every(v => v === 0)
      return Object.assign({}, this.seriesItemOptions, {
        data,
        lineColor: isAllZero ? 'rgba(44, 104, 255, 1)' : {
          linearGradient: { x1: 0, y1: 0, x2: 1, y2: 0 },
          stops: [
            [0, 'rgba(122, 243, 236, 1)'],
            [1, 'rgba(44, 104, 255, 1)']
          ]
        }
      })
    },

    /**
     * 折线图数据总配置
     */
    seriesOptions (dataArray) {
      return {
        series: dataArray.map(data => this.seriesItem(data))
      }
    },

    /**
     * 折线图总配置
     */
    chartsConfigOptions ([categories, data]) {
      return Object.assign({}, this.highchartsOptions, this.xAxisOption(categories), this.seriesOptions([data]))
    },

    /**
     * 生成趋势折线图
     */
    createTrendChart () {
      const [category, data] = this.trendData.reduce((res, item) => {
        const [dates, storges] = res
        const { date, storge } = item
        dates.push(date)
        storges.push(storge)
        return [dates, storges]
      }, [[], []])
      const el = this.$refs['trend-chart']
      const config = this.chartsConfigOptions([category, data])
      this.trendDataChart = Highcharts.chart(el, config)
      return this.trendDataChart
    }
  }
}
